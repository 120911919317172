<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :options.sync="options"
    :footer-props="{
      'items-per-page-options': datatables_rowsPerPageItems,
    }"
    :server-items-length="totalItems"
    class="elevation-1"
    :no-data-text="noDataText"
  >
    <template v-slot:loading>
      Loading items..
    </template>

    <template v-slot:[`footer.page-text`]="props">
      {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <div :class="getStatusClass(item.status)">{{ item.status }}</div>
    </template>

  </v-data-table>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'SystemJobsTable',

  props: {
    headers: { type: Array, required: true },
    jobType: { type: String, required: true },
    updateKey: { type: String, required: true },
  },

  data () {
    return {
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'systemJobsTable_options',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    jobsEndpoint () {
      return '/a/system/jobs?job_type=' + this.jobType
    },

    noDataText () {
      return 'No ' + this.jobType + ' jobs found'
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
    updateKey: {
      handler () {
        this.fetchData()
      },
    },
  },

  methods: {
    fetchData: function () {
      this.loading = true
      var myURL = this.jobsEndpoint + '&xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, [], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // only get today's records
      myURL += '&entry_at=' + this.$dateTime.now().toISODate() + '~'

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },
  
    getStatusClass: function (status) {
      if (status === 'Open') { return 'open' }
      if (status === 'In progress') { return 'in_progress' }
      if (status === 'Completed') { return 'completed' }
      if (status === 'Completed with errors') { return 'completed_with_errors' }
      if (status === 'Failed') { return 'failed' }
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>

div.completed {
  color: green;
}
div.failed {
  color: red;
}
div.in_progress, div.completed_with_errors {
  color: orange;
}
div.open {
  color: blue;
}

</style>